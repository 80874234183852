<svelte:options tag="private-pro-popover"/>

<script>
    import { onMount } from 'svelte';

    let showButtons = true;

    // Funksjon for å sette en cookie
    function setCookie(name, value, days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }

    // Funksjon for å hente en cookie
    function getCookie(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    // Sjekk om cookie er satt ved montering av komponent
    onMount(() => {
        if (getCookie('user_choice')) {
            showButtons = false;
        }
    });

    // Hent den nåværende URL-stien uten leading /
    function getCurrentPath() {
        let currentPath = window.location.pathname;
        currentPath = currentPath.replace(/\/(proff|privat)/, '');
        return currentPath.startsWith('/') ? currentPath.slice(1) : currentPath;
    }

    // Håndter valg av proff
    function handleProClick() {
        setCookie('user_choice', 'proff', 30);
        window.location.href = `/proff/${getCurrentPath()}`;
    }

    // Håndter valg av privat
    function handlePrivateClick() {
        setCookie('user_choice', 'privat', 30);
        window.location.href = `/privat/${getCurrentPath()}`;
    }
</script>
<div class="private-pro-popover">
    {#if showButtons}
        <div class="popover">
            <div class="popover__content">
                <h2 class="popover__title title--sans mb-8">Velg uterom</h2>
                <div class="grid md:grid-cols-2 gap-6">
                    <div class="col-span-1">
                        <button on:click={handlePrivateClick} class="btn btn-secondary">Privat uterom <i class="fa-light fa-chevron-right"></i></button>
                    </div>
                    <div class="col-span-1">
                        <button on:click={handleProClick} class="btn btn-secondary">Offentlig uterom <i class="fa-light fa-chevron-right"></i></button>
                    </div>
                </div>
            </div>
            <div class="popover__footer">
                <p class="text--small">For å gi deg best mulig opplevelse av våre produkter og tjenester har vi delt inn nettsiden for å tilpasse seg ditt behov.</p>
            </div>
        </div>
    {/if}
</div>

<style>
    @import '/static/website/bundle.css';
    @import "https://kit.fontawesome.com/62abe87950.css";
</style>
